import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  border: 0;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 93px;
`;

const Button = styled.button`
  background: #ece9e3;
  border: 2px solid #ece9e3;
  border-radius: 15px;
  width: 140px;
  height: 60px;
  font-family: "Metro-Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  color: #292c15;
`;

const Value = styled.div`
  margin-right: 22px;
  margin-left: 22px;
  font-family: "Metro-Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  /* identical to box height, or 83% */

  text-align: center;

  color: #292c15;
`;
const ValueUnit = styled.div`
  font-weight: 700;
  font-size: 18px;
  opacity: 0.5;
`;
const Input = styled.input`
  background-color: transparent;
  font-size: 25px;
  max-width: 30px;
  text-align: center;
  border: none;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
  input[type="number"] {
    display: none;
  }
`;
function Counter({ unit, val, setCounterValue, style }) {
  return (
    <Wrapper style={style}>
      <Button onClick={() => setCounterValue(val > 0 ? parseInt(val) - 1 : 0)}>
        -
      </Button>
      <Value>
        <Input
          type="number"
          value={val}
          onChange={(event) => {
            var inputVal = event.target.value;
            setCounterValue(inputVal < 0 ? 0 : inputVal);
          }}
        />
        <ValueUnit>{unit}</ValueUnit>
      </Value>
      <Button
        onClick={() => {
          setCounterValue(
            val != "" ? parseInt(val) + 1 : parseInt(val + 0) + 1
          );
        }}
      >
        +
      </Button>
    </Wrapper>
  );
}

export default Counter;
