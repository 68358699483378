import {
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
} from './ressources';

const items = [
  { header: "Un accès dédié", active: true },
  { header: "Un suivi au quotidien" },
  { header: "Des résultats visibles" },
  { header: "Actualité" },
  { header: "Boutique en ligne" },
  { header: "Poser une question a un Veterinaire " },
];

const listImages = [banner1, banner2, banner5, banner3, banner6, banner4];

const languages = [
  {label: 'FR', value: 'fr'},
  {label: 'EN', value: 'en'},
];

export {items, listImages, languages};
