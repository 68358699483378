import React from "react";
import { CatHuile, DogHuile, rightArrow } from "../../common/ressources";
import styled from "@emotion/styled";

const Card = styled.div`
  background: #f3deb4;
  border-radius: 15px;
  max-width: 510px;
  margin: 0 auto;
  height: 257px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 49px;
  @media (max-width: 768px) {
    margin-right: -25px;
    margin-left: -25px;
  }
`;

const HuileImg = styled.img`
  width: 140px;
  height: 200px;
`;

const CardTitle = styled.h3`
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  margin-bottom: 10px;
  color: #292c15;
`;
const CardInfo = styled.p`
  font-family: "Metro-Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #292c15;
  margin-bottom: 40px;
  opacity: 0.7;
`;

const CardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 52px;
  gap: 8px;
  background: #292c15;
  border-radius: 4px;
  font-family: "Metro-Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border: 0;
  color: #ffffff;
  cursor: pointer;
`;

function ProductCard({ animal, info, href }) {
  return (
    <Card>
      <HuileImg src={animal == "Chat" ? CatHuile : DogHuile} alt="" />
      <div>
        <CardTitle>Huile de CBD pour {animal}</CardTitle>
        <CardInfo> {info} </CardInfo>
        <a style={{ textDecoration: "none" }} target={"_blank"} href={href}>
          <CardButton>
            Commander <img src={rightArrow} alt="" />
          </CardButton>
        </a>
      </div>
    </Card>
  );
}

export default ProductCard;
