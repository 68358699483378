/** @format */

import {
  horse,
  bgDoctorMobile,
  geoCat,
  geoDog,
  geoHorse,
  footer,
  navGeo,
  mobileGeo,
  bgGeo,
  bgGeoCar,
  bgCurve,
  geoReptile,
  topographyDoctor,
  heroBg,
  geoHorseMobile,
  bgGeoBiege,
  bgAbout,
  bgProduct1,
  bgProduct4,
  bgProduct2,
  bgProduct3,
  teraMobileBg,
  horse_mobile,
  reptile_mobile,
} from "./ressources.js";

const breakpoints = [600, 750, 802, 1200];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

const styles = {
  global: {
    "*": {
      fontFamily: "Metro-Sans",
    },
    input: {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        margin: 0,
      },
      "&[type=number]": {
        "-webkit-appearance": "textfield",
        "-moz-appearance": "textfield",
      },
    },
    body: {
      margin: 0,
      padding: 0,
    },
    html: {
      margin: 0,
      padding: 0,
    },
  },

  toggle: {
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    [mq[0]]: {
      display: "none",
    },
  },

  dropdown: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
    position: "relative",
    display: "none",
    flexDirection: "column",
    li: {
      paddingTop: "8px",
      a: {
        fontSize: "16px",
        fontWeight: "500",
        color: "white",
        lineHeight: "24px",
        textDecoration: "none",
      },
    },
  },

  sidelogo: {
    paddingLeft: "21px",
    paddingRight: "47px",
    paddingTop: "49px",
    paddingBottom: "49px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dropdownWrapper: {
    position: "relative",
    alignSelf: "stretch",
    width: "52px",
    ["&>div:hover ul"]: {
      display: "flex",
    },
  },

  dropdownContainer: {
    position: "absolute",
    margin: "auto",
    top: 0,
    left: 0,
    backgroundColor: "#292C15",
    padding: "8px",
    borderRadius: "8px",
    "&.dropdown-sidebar": {
      position: "relative",
      margin: 0,
      padding: "9px",
      img: {
        width: "12px",
      },
    },
  },

  dropdownCurrent: {
    display: "flex",
    span: {
      fontSize: "16px",
      fontWeight: "500",
      color: "white",
      lineHeight: "24px",
      marginRight: "8px",
    },
  },

  sidebar: {
    flexDirection: "column",
    zIndex: 9999,
    position: "fixed",
    width: "302px",
    height: "100vh",
    overflow: "scroll",
    left: "-100%",
    transition: "left 0.8s ease-in-out",
    top: 0,
    backgroundColor: "#F3EEE4",
    opacity: 1,
    ["&>ul"]: {
      margin: 0,
      paddingLeft: "21px",
      paddingRight: "47px",
      display: "flex",
      flexDirection: "column",

      ["&>li"]: {
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid #D6D2C7",
        padding: "24px 0px",
        width: "100%",
        ["&>a"]: {
          textDecoration: "none",
          color: "black",
        },
        ["&>span"]: {
          display: "block",
          marginLeft: "21px",
          marginTop: "31px",
          display: "flex",
          alignItems: "center",
          img: {
            margin: 0,
            paddingLeft: 0,
          },
        },
      },
    },
  },

  close: {
    margin: "41px 0px",
    paddingLeft: "21px",
    width: "100%",
    boxSizing: "border-box",
    ["&>div"]: {
      right: "47px",
    },
  },

  app: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    section: {
      padding: "0px 20px",
      boxSizing: "border-box",
    },
  },

  intro: {
    backgroundImage: `url(${teraMobileBg}),url(${mobileGeo})`,
    backgroundSize: "cover",
    // backgroundPosition: 'center top 380px,right top 120px,top',
    padding: "0!important",
    backgroundRepeat: "no-repeat,no-repeat,no-repeat",
    width: "100%",
    alignItems: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 80px)",
    marginTop: 80,
    mainContainer: {
      textAlign: "center!important",
      justifyContent: "center!important",
    },
    overlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      background: "#292C15",
      mixBlendMode: "multiply",
      opacity: "0.5",
    },
    [mq[0]]: {
      backgroundImage: `url(${heroBg}),url(${bgGeo})`,
      backgroundColor: "#F6F3EE",
      // backgroundPosition: 'right bottom,right bottom,top',
      // backgroundSize: 'contain,90%,cover',
      padding: "0px 10px !important",
      backgroundSize: "cover",
      height: "calc(100vh - 160px)",
      marginTop: 160,
    },

    [mq[1]]: {
      backgroundColor: "#F6F3EE",
      // backgroundPosition: 'right bottom,right bottom,top',
      // backgroundSize: 'contain,90%,cover',
    },

    [mq[2]]: {
      // backgroundSize: 'contain,70%,cover',
    },

    [mq[3]]: {
      // backgroundPosition: 'right bottom ,right bottom,top',
      // backgroundSize: 'contain,auto,cover',
    },
  },
  about: {
    width: "100%",
    backgroundColor: "#EFECE5",
    backgroundImage: `url('${bgAbout}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxSizing: "border-box",
    paddingTop: 0,
    paddingBottom: "40px!important",
    mainContainer: {
      width: "100%",
      display: "flex",
      maxWidth: "1162px",
      margin: "0 auto",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
      [mq[1]]: {
        flexDirection: "row",
        paddingTop: "101px!important",
        paddingBottom: "96px!important",
      },
      img: {
        width: "100%",
        [mq[1]]: {
          width: "auto",
        },
      },
    },
    container: {
      maxWidth: 422,

      h2: {
        fontFamily: "'Inconsolata'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "32px",
        lineHeight: "34px",
        color: "#292C15",
        marginBottom: 23,
        marginTop: 37,
        [mq[0]]: {
          fontSize: "38px",
          lineHeight: "40px",
        },
      },
      p: {
        fontFamily: "'Metro-Sans'",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "35px",
        color: "#292C15",
        opacity: "0.7",
      },
    },
  },
  mainContainer: {
    maxWidth: "1440px",
    width: "100%",
  },

  container: {
    justifyContent: "space-between",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    gap: "42px",

    [mq[0]]: {
      gap: "0px",
      justifyContent: "start",
      flexDirection: "row",
      textAlign: "left",
    },
  },

  desc: {
    // order: 2,
    // marginTop: '0',
    // flex: 0.495,
    // boxSizing: 'border-box',
    // padding: '0 20px 118px 20px',
    // backgroundColor: '#FFF',
    textAlign: "center",
    [mq[0]]: {
      padding: 0,
      order: 0,
      background: "none",
    },

    h1: {
      marginTop: 0,
      marginBottom: "12px",
      fontWeight: "800",
      fontSize: "42px",
      letterSpacing: "-1px",
      fontFamily: "Inconsolata",
      color: "#fff",
      lineHeight: "50px",

      [mq[0]]: {
        fontSize: "32px",
      },
      [mq[1]]: {
        fontSize: "42px",
      },
      [mq[2]]: {
        fontSize: "48px",
        lineHeight: "61px",
      },
      [mq[3]]: {
        fontSize: "58px",
      },
    },
    p: {
      marginTop: 0,
      marginBottom: "32px",
      fontSize: "16px",
      lineHeight: "30px",
      fontWeight: "400",
      color: "#626353",

      [mq[0]]: {
        fontSize: "16px",
      },
      [mq[1]]: {
        fontSize: "16px",
      },
      [mq[2]]: {
        fontSize: "20px",
      },
      [mq[3]]: {
        fontSize: "20px",
      },
    },
    a: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "22px",
      textDecoration: "none",
      color: "#fff",
      display: "inline-block",
      padding: "15px 36px 15px 36px",
      backgroundColor: "#696737",
      borderRadius: "6px",
      ["&:hover"]: {
        backgroundColor: "#54522C",
      },
    },
  },

  row: {
    position: "relative",
    zIndex: 3,
    marginTop: "100px",
    marginBottom: "0px",
    listStyleType: "none",
    alignItems: "center",
    display: "flex",
    width: "100%",
    padding: 0,
    flexDirection: "column",
    maxWidth: "1044px",
    marginLeft: "auto",
    marginRight: "auto",
    gap: "20px",
    [mq[0]]: {
      justifyContent: "space-between",
      alignItems: "stretch",
      marginTop: "160px",
      flexDirection: "row-reverse",
    },

    li: {
      display: "flex",
      gap: "20px",
      flexDirection: "column",
      borderRadius: "30px",
      padding: "35px 43px",
      alignItems: "center",
      backgroundColor: "#696737",
      width: "80%",
      boxSizing: "border-box",
      [mq[0]]: {
        width: "31.4176245%",
      },
      span: {
        fontFamily: "Inconsolata",
        fontWeight: "700",
        fontSize: "22px",
        lineHeight: "32px",
        letterSpacing: "0.1px",
        textAlign: "center",
        color: "#fff",
      },
      img: {
        width: "48px",
      },
    },
  },

  preheader: {
    backgroundColor: "#696737",
    backgroundImage: `url('${navGeo}')`,
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "multiply",
    backgroundSize: "cover",
    position: "fixed",
    width: "100%",
    position: "fixed",
    zIndex: 999,
    alignItems: "center",
    display: "flex",
    height: "80px",
    alignItems: "center",
    justifyContent: "center",
    [mq[0]]: {
      position: "fixed",
    },
    info: {
      fontFamily: "'Inconsolata'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
  },
  headerWrapper: {
    backgroundColor: "#fff",
    backgroundImage: `url('${navGeo}')`,
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "multiply",
    backgroundSize: "cover",
    position: "fixed",
    width: "100%",
    position: "fixed",
    zIndex: 999,
    alignItems: "center",
    display: "none",
    height: "80px",
    alignItems: "center",
    justifyContent: "center",
    top: -80,
    [mq[0]]: {
      position: "fixed",
      display: "flex",
      top: 80,
    },
  },

  header: {
    maxWidth: "1162px",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
  },

  navbar: {
    gap: "5px",
    padding: 0,
    display: "none",
    listStyleType: "none",
    alignItems: "center",
    marginRight: "auto",
    justifyContent: "center",

    [mq[0]]: {
      display: "flex",
      gap: "20px",
    },

    [mq[1]]: {
      display: "flex",
      gap: "58px",
    },

    li: {
      a: {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        fontFamily: "arial",
        color: "#292C15",
      },
    },
  },

  logo: {
    marginRight: 24,
    display: "flex",
    alignItems: "center",
  },

  shop: {
    gap: "10px",
    padding: 0,
    display: "flex",
    listStyleType: "none",
    alignItems: "center",
    justifyContent: "center",

    [mq[2]]: {
      gap: "50px",
    },

    li: {
      a: {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        fontFamily: "arial",
        color: "#FFF",
        ["img:first-of-type"]: {
          display: "none",
          [mq[0]]: {
            display: "block",
          },
        },
        ["img:last-of-type"]: {
          display: "block",
          [mq[0]]: {
            display: "none",
          },
        },
      },
    },

    ["&>li:first-of-type"]: {
      display: "none",
      [mq[0]]: {
        display: "flex",
      },
    },

    ["&>li:last-of-type"]: {
      display: "none",
      [mq[0]]: {
        display: "flex",
      },
    },
  },

  product: {
    flex: 0.510576923,
    position: "relative",
    img: {
      maxWidth: "453px",
      width: "231px",
      position: "relative",
      right: 0,
      top: 0,
      zIndex: 10,

      [mq[0]]: {
        top: "-74px",
        maxWidth: "453px",
        width: "100%",
        position: "absolute",
      },
    },
  },

  next: {
    borderRadius: "4px",
    backgroundColor: "#292C15",
    gap: "41px",
    width: "156px",
    display: "inline-flex",
    height: "56px",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    span: {
      color: "#696737",
      fontSize: "16px",
      fontFamily: "Metro-Sans",
      fontWeight: "700",
    },
  },

  recomand: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingBottom: "172px",
    paddingTop: "100px",
    padding: "0 !important",
    boxSizing: "border-box",
    mainContainer: {
      maxWidth: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "23px",
      paddingRight: "23px",
      paddingTop: "34px",
      backgroundImage: `url('${bgDoctorMobile}')`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      boxSizing: "border-box",
      backgroundColor: "#292C15",
      width: "100%",
      minHeight: 660,
      alignItems: "center",
      [mq[1]]: {
        paddingLeft: "88px",
        paddingTop: "0px",
        flexDirection: "row",
        backgroundImage: `url('${topographyDoctor}')`,
      },
    },
    doctor: {
      flex: 0.5,
      position: "relative",
      alignSelf: "end",
      div: {
        ["&>.staticImage"]: {
          position: "relative",
          // maxWidth: "505px",
          width: "100%",
        },
      },
      [mq[1]]: {
        justifyContent: "start",
        marginTop: 0,
      },
    },
    container: {
      flex: 0.45,
      textAlign: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [mq[1]]: {
        textAlign: "left",
        alignItems: "start",
        maxWidth: "420px",
      },

      h1: {
        marginTop: 0,
        marginBottom: "16px",
        color: "#FFF",
        fontSize: "32px",
        lineHeight: "34px",
        fontFamily: "Inconsolata",
        fontWeight: "700",
        [mq[0]]: {
          fontSize: "38px",
          lineHeight: "40px",
        },
      },
      h2: {
        marginTop: 0,
        marginBottom: "21px",
        color: "#FFF",
        fontSize: "18px",
        lineHeight: "16px",
        fontFamily: "Inconsolata",
        fontWeight: "700",
      },
      p: {
        marginTop: 0,
        marginBottom: "30px",
        color: "#FFF",
        fontSize: "14px",
        lineHeight: "35px",
        lineHeight: "35px",
        fontFamily: "Metro-Sans",
        fontWeight: "500",
        opacity: 0.7,
        [mq[0]]: {
          fontSize: "18px",
        },
      },
      a: {
        borderRadius: "4px",
        backgroundColor: "#696737",
        gap: "41px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        padding: "18px 24px",
        marginBottom: 20,
        width: "90%",
        [mq[0]]: {
          width: "370px",
        },

        ["&:last-child"]: {
          backgroundColor: "#fff",
          span: {
            color: "#696737",
          },
        },
        ["&:hover"]: {
          backgroundColor: "#CCCCCC",
        },
        span: {
          color: "#F5F2ED",
          fontSize: "16px",
          fontFamily: "Metro-Sans",
          fontWeight: "700",
        },
      },
    },
  },

  problematique: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#EFECE5",
    backgroundImage: `url('${bgAbout}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    mainContainer: {
      marginTop: "64px!important",
      marginBottom: "91px",
      flexGrow: 1,
      alignItems: "center",
      flexDirection: "column",

      maxWidth: "1044px",
      boxSizing: "border-box",
      display: "flex",
      [mq[0]]: {
        marginTop: "120px!important",
      },
      h1: {
        textAlign: "center",
        fontFamily: "Inconsolata",
        marginBottom: "75px",
        maxWidth: "646px",
        fontWeight: "700",
        lineHeight: "47px",
        fontSize: "32px",
        paddingTop: 20,
        marginTop: 0,
        color: "#292C15",
      },
    },
    pretitle: {
      fontFamily: "'Inconsolata'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "32px",
      lineHeight: "48px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#696737",
      [mq[0]]: {
        fontSize: "38px",
        lineHeight: "48px",
      },
    },
    container: {
      ul: {
        display: "flex",
        padding: 0,
        margin: "0",
        flexDirection: "column",
        gap: "70px",
        [mq[0]]: {
          flexDirection: "row",
        },
        li: {
          maxWidth: "100%",
          [mq[0]]: {
            maxWidth: "300px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",

          h3: {
            color: "#292C15",
            margin: 0,
            fontFamily: "'Metro-Sans'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "22px",
            lineHeight: "32px",
            textAlign: "center",
            letterSpacing: "0.1px",
          },
          p: {
            textAlign: "center",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#626353",
            lineHeight: "20px",
            letterSpacing: "0.2px",
            margin: 0,
            padding: "0 44px",
            [mq[0]]: {
              padding: 0,
            },
          },
        },
      },
    },
  },

  caracteristiques: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundImage: `url('${bgGeoCar}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingBottom: "40px !important",
    [mq[2]]: {
      paddingBottom: "110px !important",
    },
    wrapper: {
      padding: 0,
    },
    mainContainer: {
      flexGrow: 1,
      alignItems: "center",
      flexDirection: "column",
      maxWidth: "1145px",
      boxSizing: "border-box",
      display: "flex",

      h1: {
        fontFamily: "Inconsolata",
        textAlign: "center",
        fontSize: "28px",
        lineHeight: "32px",
        maxWidth: "638px",
        fontWeight: "700",
        color: "#fff",
        width: "320px",
        marginTop: "43px",
        [mq[2]]: {
          marginTop: "102px",
          width: "auto",
          fontSize: "38px",
          lineHeight: "48px",
        },
      },
    },
    soon: {
      marginBottom: "69px",
      display: "flex",
      alignItems: "center",
      fontFamily: "'Inconsolata'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "48px",
      textAlign: "center",
      color: "#FFFFFF",
      flexDirection: "column",
      [mq[2]]: {
        marginBottom: "80px",
        flexDirection: "row",
      },
      badge: {
        background: "#F0A383",
        borderRadius: "15px",
        marginLeft: 10,
        paddingLeft: 20,
        paddingRight: 20,
        height: 48,
        color: "#292C15",
        fontSize: "24px",
        lineHeight: "48px",
        fontFamily: "Inconsolata",
        display: "flex",
        alignItems: "center",
      },
    },
    activeSlide: {
      backgroundColor: "#F9E3BA",
      p: {
        color: "#292C15",
      },
      h3: {
        color: "#292C15",
      },
    },

    slide: {
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
      width: "250px",
      padding: "27px 24px",
      borderRadius: "30px",
      marginLeft: "8px",
      boxSizing: "border-box",
      transition: "all 0.2s linear",
      h3: {
        fontWeight: "700",
        fontSize: "18px",
        color: "#FAFAFB",
        margin: "10px 0px 5px 0px",
        [mq[2]]: {
          fontSize: "20px",
        },
      },
      p: {
        textAlign: "left",
        fontWeight: "400",
        fontSize: "14px",
        color: "#FAFAFB",
        opacity: 0.6,
        margin: "0px 0px 0px",
        [mq[2]]: {
          fontSize: "16px",
        },
      },
    },

    container: {
      display: "flex",
      alignSelf: "stretch",
      flexDirection: "column",
      justifyContent: "center",
      [mq[0]]: {
        flexDirection: "row",
      },

      ul: {
        display: "none",

        [mq[0]]: {
          display: "flex",
        },

        flex: 0.3,
        flexDirection: "column",
        justifyContent: "center",
        padding: "0px",
        margin: 0,

        li: {
          transition: "all 0.1s ease-in",
          display: "flex",
          alignItems: "end",
          width: "100%",
          boxSizing: "border-box",
          flexDirection: "column",
          padding: "32px",
          borderRadius: "30px",
          ["&:hover"]: {
            cursor: "pointer",
          },
          img: {
            marginBottom: "16px",
          },
          h3: {
            fontWeight: "700",
            fontSize: "20px",
            color: "#FAFAFB",
            marginTop: "0px",
            marginBottom: "12px",
            textAlign: "right",
          },
          p: {
            marginTop: 0,
            marginBottom: 0,
            textAlign: "right",
            fontWeight: "400",
            fontSize: "16px",
            color: "#FAFAFB",
            lineHeight: "27px",
            opacity: 0.6,
          },
        },
      },

      ["ul:last-of-type"]: {
        li: {
          alignItems: "start",
          p: {
            textAlign: "left",
          },
          h3: {
            textAlign: "left",
          },
        },
      },
    },

    slider: {
      width: "100vw",
      overflow: "hidden",
      display: "block",
      marginTop: "40px",
      paddingLeft: "22px",

      [mq[0]]: {
        display: "none",
        paddingLeft: 0,
      },
    },

    banner: {
      display: "flex",
      flex: 0.4,
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      display: "flex",

      div: {
        position: "relative",
        img: {
          position: "absolute",
          transition: "all 0.3s ease-in-out",
          width: "90%",
          height: "95%",
          left: "12px",
          top: "10px",
          borderRadius: "25px",

          [mq[0]]: {
            left: "12px",
            top: "10px",
          },
          ["&:first-of-type"]: {
            position: "relative",
            width: "243px",
            height: "auto",
            opacity: 1,
            borderRadius: "0",
            zIndex: 50,
            top: 0,
            left: 0,
            [mq[0]]: {
              width: "100%",
            },
          },
        },
      },
    },
  },

  ourProducts: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#F9E3BA",
    backgroundImage: `url('${bgGeoBiege}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxSizing: "border-box",
      h1: {
        fontWeight: "700",
        fontSize: "30px",
        lineHeight: "48px",
        color: "#292C15",
        fontFamily: "Inconsolata",
        marginTop: "40px",
        marginBottom: "0px",
        textAlign: "center",
        [mq[0]]: {
          fontSize: "38px",
          lineHeight: "48px",
          marginTop: "50px",
        },
      },
      p: {
        color: "#626353",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "32px",
        marginTop: 0,
        textAlign: "center",
        maxWidth: "790px",
      },
      ul: {
        display: "flex",
        justifyContent: "space-between",
        alignSelf: "stretch",
        padding: 0,
        margin: 0,
        flexDirection: "column",
        alignItems: "center",

        [mq[0]]: {
          flexDirection: "row",
        },

        li: {
          marginTop: "173px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "stretch",
          //backgroundColor: "#FAD9F1",
          marginTop: "204px",
          borderRadius: "30px",
          [mq[0]]: {
            width: "48.9463602%",
          },
          ["&>.static-image"]: {
            marginTop: "-158px",
            marginBottom: "18px",
            width: "290px",
            [mq[0]]: {
              width: "auto",
            },
          },
          h3: {
            fontFamily: "Inconsolata",
            fontWeight: "700",
            fontSize: "24px",
            textAlign: "center",
            marginBottom: "10px",
            marginTop: 0,
            color: "#292C15",
            [mq[0]]: {
              fontSize: "32px",
            },
          },
          p: {
            minHeight: "44px",
            display: "flex",
            alignItems: "center",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "22px",
            marginTop: 0,
            marginBottom: "10px",
            maxWidth: "338px",
            color: "#292C15",
            opacity: 0.7,
            textAlign: "center",
            backgroundSize: "cover",
            [mq[0]]: {
              fontSize: "18px",
              backgroundSize: "contain",
            },
          },
          ["& .price"]: {
            opacity: 1,
          },
          a: {
            borderRadius: "4px",
            backgroundColor: "#292C15",
            padding: "19px 30px",
            display: "inline-flex",
            alignItems: "center",
            gap: "14px",
            justifyContent: "space-evenly",
            textDecoration: "none",
            marginBottom: "41px",
            ["&:hover"]: {
              backgroundColor: "#212311",
            },
            span: {
              color: "#fff",
              fontSize: "18px",
              fontFamily: "Metro-Sans",
              lineHeight: "24px",
              fontWeight: "700",
            },
          },
          ["p:last-of-type"]: {
            marginBottom: "33px",
            color: "#696737",
            fontWeight: "700",
            fontSize: "24px",
          },
        },

        // ["li:first-of-type"]: {
        //   backgroundImage: `url(${geoCat})`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundColor: "#FAD9F1",
        // },

        // ["li:last-of-type"]: {
        //   backgroundImage: `url(${geoDog})`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundColor: "#F9E3BA",
        // },
      },
    },
    btn: {
      display: "inline-flex",
      padding: "15px 36px",
      alignItems: "center",
      justifyContent: "center",
      color: "#FFFFFF",
      textDecoration: "none",
      fontWeight: "700",
      fontSize: "12px",
      borderRadius: "6px",
      //marginBottom: "0",
      background: "#696737",
      marginTop: "25px",
      cursor: "pointer",
      span: {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  productList: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "stretch",
    padding: "20px 0px 0px 0px",
    margin: 0,
    flexDirection: "column",
    marginBottom: "30px",
    [mq[0]]: {
      flexDirection: "row",
      padding: "40px 184px",
      gap: "40px 40px",
    },
    flexWrap: "wrap",
    gap: "15px",
    product: {
      [mq[0]]: {
        flex: "1 1 25%",
        width: "100%",
        minHeight: "604x",
      },
      width: "auto",
      minHeight: "604x",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "16px",
      padding: "20px !important",
      productBg1: {
        backgroundImage: `url(${bgProduct1})`,
        backgroundPositionX: "center",
      },
      productBg2: {
        backgroundImage: `url(${bgProduct2})`,
        backgroundPositionX: "center",
      },
      productBg3: {
        backgroundImage: `url(${bgProduct3})`,
        backgroundPositionX: "center",
      },
      productBg4: {
        backgroundImage: `url(${bgProduct4})`,
        backgroundPositionX: "left",
      },
      h3: {
        fontFamily: "Inconsolata",
        fontWeight: "700",
        fontSize: "21px",
        lineHeight: "29px",
        //marginBottom: "10px",
        margin: 0,
        color: "#FFFFFF",
        paddingTop: "21px",
        maxWidth: "331px",
      },
      p: {
        fontFamily: "'Metro-Sans'",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.2px",
        color: "#FFFFFF",
        opacity: "0.7",
        margin: "0",
        padding: "8px 0px",
        textAlign: "left",
        opacity: "0.7",
        maxWidth: "331px",
      },
      h4: {
        fontFamily: "'Metro-Sans'",
        fontWeight: "700",
        fontSize: "22px",
        lineHeight: "32px",
        letterSpacing: "0.2",
        color: "#FFFFFF",
        // bottom: "0",
        maxWidth: "331px",
      },
      a: {
        borderRadius: "4px",
        backgroundColor: "#292C15",
        padding: "15px 25px",
        display: "inline-flex",
        alignItems: "center",
        gap: "14px",
        justifyContent: "space-evenly",
        textDecoration: "none",
        ["&:hover"]: {
          backgroundColor: "#212311",
        },
        span: {
          color: "#fff",
          fontSize: "16px",
          fontFamily: "Metro-Sans",
          lineHeight: "22px",
          fontWeight: "700",
        },
      },
    },
  },

  available: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    boxSizing: "border-box",
    paddingBottom: "181px",
    paddingLeft: "0!important",
    paddingRight: "0!important",

    mainContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "100%",
      //marginBottom: "146px",
      [mq[0]]: {
        // marginBottom: "181px",
      },
    },

    container: {
      width: "100%",
      backgroundRepeat: "no-repeat,no-repeat,no-repeat",
      backgroundSize: "auto, auto, auto 100%",
      backgroundPosition: "center",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      height: "100%",
      [mq[0]]: {
        backgroundSize: "cover, auto, auto 100%",
        flexDirection: "row",
        height: "602px",
        backgroundPosition: "left,right,right",
      },
    },
    horse: {
      height: "auto",
      backgroundImage: `url(${horse})`,
      backgroundPositionX: "70%",
      [mq[0]]: {
        height: "602px",
        backgroundPosition: "left,right,right",
      },
    },

    reptile: {
      backgroundImage: `url(${geoReptile})`,
      height: "100%",
      backgroundPositionX: "70%",
      [mq[1]]: {
        backgroundImage: `url(${geoReptile})`,
        height: "602px",
      },
    },

    imageWrapper: {
      flex: 0.42,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      div: {
        ["&>.static-image"]: {
          width: "195px",
          position: "relative",
          left: "calc(50% - 110px)",
          marginTop: "-175px",
          marginBottom: "44px",
          [mq[0]]: {
            marginTop: "0px",
            marginBottom: "0px",
            position: "absolute",
            width: "100%",
            maxWidth: "382px",
            top: "50%",
            left: "50%",
            transform: "translate(-70%,-50%)",
          },
        },
      },
    },

    subdiv: {
      flex: 0.43,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 30,
      [mq[1]]: {
        alignItems: "start",
        marginTop: 0,
      },
      a: {
        display: "inline-flex",
        padding: "10px",
        alignItems: "center",
        justifyContent: "center",
        color: "#292C15",
        textDecoration: "none",
        fontWeight: "700",
        fontSize: "13px",
        borderRadius: "6px",
        marginBottom: "27px",

        ["&.first"]: {
          backgroundColor: "#F0A383",
        },
        ["&.second"]: {
          backgroundColor: "#E4FFDB",
        },
      },
      h1: {
        marginTop: "0px",
        fontFamily: "Inconsolata",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "32px",
        textAlign: "left",
        color: "white",
        paddingBottom: 20,
        textAlign: "center",
        paddingRight: "15px",
        paddingLeft: "15px",
        [mq[0]]: {
          fontSize: "42px",
          lineHeight: "50px",
          padding: 0,
          textAlign: "left",
        },
      },
      p: {
        marginTop: "0",
        fontFamily: "Inconsolata",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "35px",
        color: "white",
        opacity: 0.7,
        [mq[0]]: {
          fontSize: "18px",
        },
      },
    },
  },

  swiper: {
    ["&.swiper"]: {
      width: "100%",
      background: "white",
      height: "100%",
      [mq[2]]: {
        // paddingTop: "222px",
        // paddingBottom: "100px",
      },
    },
  },

  imgBackground: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },

  benefits: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: 5,
    position: "relative",
    padding: "0px 10px 0px 10px",
    boxSizing: "border-box",

    [mq[0]]: {
      backgroundSize: "auto",
    },

    mainContainer: {
      maxWidth: "1050px",
      display: "flex",
      justifyContent: "center",
    },
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // ['&:hover']: {
      //   cursor: 'pointer',
      // },
      h1: {
        maxWidth: "495px",
        fontFamily: "Inconsolata",
        fontSize: "32px",
        fontWeight: "700",
        lineHeight: "48px",
        textAlign: "center",
        color: "#292C15",
        marginTop: "45px",
        marginBottom: "56px",
        [mq[0]]: {
          fontSize: "38px",
          lineHeight: "48px",
          marginTop: "102px",
        },
      },
      ul: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        gap: 15,
        marginTop: 0,
        marginBottom: "47px",
        [mq[0]]: {
          marginBottom: "132px",
        },
        li: {
          backgroundColor: "#fff",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          justifyContent: "center",
          padding: "32px",
          boxSizing: "border-box",
          cursor: "pointer",
          div: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 12,
            p: {
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "24px",
              color: "#292C15",
              margin: 0,
              height: "auto",

              [mq[0]]: {
                fontSize: "20px",
                lineHeight: "24px",
              },
            },
          },
          p: {
            fontWeight: 400,
            fontSize: "18px",
            color: "#696737",
            lineHeight: "32px",
            height: 0,
            margin: 0,
            overflow: "hidden",
            ["&.toggled"]: {
              height: "auto",
            },
          },
        },
      },
    },
  },

  join: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // zIndex: 5,
    position: "relative",
    background: "#F9E3BA",
    mainContainer: {
      position: "relative",
      zIndex: 2,
    },
    inputEmail: {
      display: "flex",
      flexDirection: "column",
      span: {
        textAlign: "center",
        [mq[0]]: {
          textAlign: "left",
        },
      },
    },
    h1: {
      fontFamily: "Inconsolata",
      fontWeight: 700,
      fontSize: "38px",
      color: "#474835",
      marginTop: "67px",
      marginBottom: "24px",
      textAlign: "center",
      [mq[0]]: {
        fontWeight: 800,
        fontSize: "60px",
        marginTop: "131px",
      },
      ["&.selected"]: {
        color: "#474835",
      },
    },
    p: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "26px",
      color: "#474835",
      maxWidth: "545px",
      marginTop: "24px",
      marginBottom: "24px",
      textAlign: "center",
      ["&.selected"]: {
        color: "#474835",
      },
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "15px",
      marginBottom: "60px",
      [mq[0]]: {
        flexDirection: "row",
        marginBottom: "131px",
      },
      input: {
        padding: "17px 0px 17px 20px",
        fontSize: "16px",
        borderRadius: "6px",
        width: "281px",
        boxSizing: "border-box",
        border: "1px solid #000",
        ["&::placeholder"]: {
          color: "#303122",
          fontSize: "16px",
          opacity: 1,
        },
      },
      a: {
        padding: "18px 24px",
        backgroundColor: "#696737",
        gap: "40px",
        textDecoration: "none",
        borderRadius: "4px",
        alignItems: "center",
        alignSelf: "center",
        [mq[0]]: {
          alignSelf: "baseline",
        },
        ["&:hover"]: {
          backgroundColor: "#54522C",
        },
        span: {
          color: "#FFF",
          fontWeight: 700,
          fontSize: "16px",
        },
      },
    },
  },

  footer: {
    zIndex: 5,
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url('${footer}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top right",

    padding: "10px",
    boxSizing: "border-box",
    paddingBottom: "50px!important",
    [mq[0]]: {
      paddingBottom: "100px!important",
    },
    mainContainer: {
      position: "relative",
      zIndex: 3,
      display: "flex",
      flexDirection: "column",
      maxWidth: "1050px",
      width: "100%",
    },
    socials: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    copyright: {
      zIndex: 5,
      position: "relative",
      justifyContent: "center",
      display: "flex",
      color: "#FFFFFF",
      fontSize: "14px",
      fontWeight: 700,
      width: "100%",
      gap: "8px",
      backgroundColor: "#22250D",
      span: {
        padding: "29px 0px",
      },
      ["span:first-of-type"]: {
        color: "#626353",
      },
    },
    container: {
      marginTop: "73px",
      display: "flex",
      //justifyContent: "space-around",
      flexDirection: "column",
      padding: "0px 30px 0px 30px",
      [mq[0]]: {
        marginTop: "86px",
      },
      [mq[1]]: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0px",
      },
      ["&>ul"]: {
        listStyleType: "none",
        padding: 0,
        marginTop: 0,
        marginBottom: "43px",
        [mq[0]]: {
          marginBottom: "104px",
        },
        li: {
          fontFamily: "'Inconsolata'",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "22px",
          lineHeight: "24px",
          letterSpacing: "0.2px",
          color: "#FFFFFF",
          display: "flex",
          alignItems: "center",
          img: {
            marginRight: 16,
          },
        },
      },
      ["ul:nth-of-type(2)"]: {
        // display: 'none',
        [mq[0]]: {
          display: "list-item",
        },
      },
      ["ul:nth-of-type(3)"]: {
        // display: 'none',
        [mq[0]]: {
          display: "list-item",
        },
      },
      ["ul:nth-of-type(4)"]: {
        // display: 'none',
        [mq[0]]: {
          display: "list-item",
        },
      },
    },
    logo: {
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      alignItems: "center",
      p: {
        color: "white",
        fontFamily: "'Metro-Sans'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0.2px",
        margin: "5px 0px !important",
      },
      a: {
        textDecoration: "none",
        color: "white",
      },
      img: {
        paddingBottom: "10px",
      },
    },
    matricule: {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      paddingTop: "30px",
      alignSelf: "center",
      [mq[0]]: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: "40px 22px 0px 22px",
        alignSelf: "unset",
      },
      p: {
        color: "white",
        fontFamily: "'Metro-Sans'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0.2px",
        margin: "5px 0px !important",
      },
    },
  },

  activeItem: {
    backgroundColor: "#F9E3BA",
    cursor: "pointer",
    p: {
      color: "#292C15 !important",
    },
    h3: {
      color: "#292C15 !important",
    },
  },

  commonBackground: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    zIndex: 1,
    visibility: "hidden",
    [mq[0]]: {
      visibility: "visible",
    },
  },

  commonBackgroundMobile: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    zIndex: 1,
    visibility: "visible",
    [mq[0]]: {
      visibility: "hidden",
    },
  },
};

export { mq };

export default styles;
